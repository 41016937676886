<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

<!--      <b-card no-body>-->
<!--        <b-tabs content-class="mt-3" fill>-->
<!--          <b-tab active class="" @click="notTryMfa">-->
<!--            <template #title>-->
<!--              Password Login<br>以密碼方式登入-->
<!--            </template>-->
<!--            <div id="my-captcha-container">-->
<!--                &lt;!&ndash; The contents of this container will be replaced by the captcha widget &ndash;&gt;-->
<!--            </div>-->
            <!-- Login v1 -->
<!--            <b-card class="mb-0 ll-loginbox">-->
<!--              &lt;!&ndash; <b-link class="kerry-logo" v-if="host > -1">-->
<!--              </b-link> &ndash;&gt;-->
<!--              <b-img  v-if="host > -1"-->
<!--                :src="require('@/assets/images/imgs/kerryexpress.svg')"-->
<!--                fluid-->
<!--                style="max-width: unset !important;-->
<!--        width: 60%; !important;margin-left:20%"-->
<!--                alt="logo"-->
<!--              />-->
<!--              <b-link class="brand-logo" v-else>-->
<!--              </b-link>-->
<!--              &lt;!&ndash; form &ndash;&gt;-->
<!--              <validation-observer-->
<!--                ref="loginForm"-->
<!--              >-->
<!--                <b-form-->
<!--                  class="auth-login-form mt-2"-->
<!--                  @submit.prevent-->
<!--                >-->
<!--                  &lt;!&ndash; username &ndash;&gt;-->
<!--                  <b-form-group-->
<!--                    label-for="username"-->
<!--                    label="Username"-->
<!--                  >-->
<!--                    <b-form-input-->
<!--                      id="username"-->
<!--                      v-model="userInfoForm.username"-->
<!--                      name="login-username"-->
<!--                      placeholder="Username"-->
<!--                      autofocus-->
<!--                      @keyup.enter="focusNext('pwd')"-->
<!--                    />-->
<!--                  </b-form-group>-->

<!--                  &lt;!&ndash; password &ndash;&gt;-->
<!--                  <b-form-group>-->
<!--                    <div class="d-flex justify-content-between">-->
<!--                      <label for="password">Password</label>-->
<!--                      &lt;!&ndash;-->
<!--                      <b-link :to="{name:'auth-forgot-password-v1'}">-->
<!--                        <small>Forgot Password?</small>-->
<!--                      </b-link>-->
<!--                      &ndash;&gt;-->
<!--                    </div>-->
<!--                      <b-input-group-->
<!--                        class="input-group-merge"-->
<!--                      >-->
<!--                        <b-form-input-->
<!--                          id="password"-->
<!--                          v-model="userInfoForm.password"-->
<!--                          :type="passwordFieldType"-->
<!--                          class="form-control-merge"-->
<!--                          name="login-password"-->
<!--                          placeholder="Password"-->
<!--                          ref="pwd"-->
<!--                        />-->

<!--                        <b-input-group-append is-text>-->
<!--                          <feather-icon-->
<!--                            class="cursor-pointer"-->
<!--                            :icon="passwordToggleIcon"-->
<!--                            @click="togglePasswordVisibility"-->
<!--                          />-->
<!--                        </b-input-group-append>-->
<!--                      </b-input-group>-->
<!--                  </b-form-group>-->

<!--                  &lt;!&ndash; checkbox &ndash;&gt;-->
<!--                  <b-form-group>-->
<!--                    <b-form-checkbox-->
<!--                      id="remember-me"-->
<!--                      v-model="status"-->
<!--                      name="checkbox-1"-->
<!--                      v-if="false"-->
<!--                    >-->
<!--                      Remember Me-->
<!--                    </b-form-checkbox>-->
<!--                  </b-form-group>-->

<!--                  &lt;!&ndash; submit button &ndash;&gt;-->
<!--                  <b-button-->
<!--                    variant="primary"-->
<!--                    type="submit"-->
<!--                    block-->
<!--                    @click.prevent="recaptcha"-->
<!--                    :disabled="disabled"-->
<!--                    :style="{'cursor': disabled ? 'no-drop' : 'pointer'}"-->
<!--                  >-->
<!--                    Sign in-->
<!--                  </b-button>-->

<!--                  <b-form-group class="pt-1 text-center">-->
<!--                    <b-link :to="{name:'track'}">-->
<!--                      <small>Public Track and Trace</small>-->
<!--                    </b-link>-->
<!--                  </b-form-group>-->
<!--                </b-form>-->
<!--              </validation-observer>-->
<!--            </b-card>-->
            <!-- /Login v1 -->
            <!-- modal -->
<!--            <b-modal-->
<!--              id="modal-details"-->
<!--              ref="modal-details"-->
<!--              centered-->
<!--              title=""-->
<!--              size="l"-->
<!--              hide-footer-->
<!--            >-->
<!--              <div class="flex-grow-1">-->
<!--                <b-card class="card-browser-states ll-backg ll-sHei ll-cardHei1">-->
<!--                  <b-card-text>-->
<!--                    <b-card-title class="text-center">-->
<!--                      <div class="kerry-mfa-logo"></div>-->

<!--                    </b-card-title>-->
<!--                    <b-card-text>-->
<!--                      <div style="text-align: center">-->
<!--                        <b>Multi Factor Authentication</b>-->
<!--                      </div>-->
<!--                      <br>-->
<!--                      <p>-->
<!--                        Notice: To enhance user experience and improve system security.New security measure will be implemented from 01-January-2025. Details will be announced in November 2024. Thanks.<br>注意:為了令用戶有方便的使用體驗，同時提昇系統的安全性。我們將會於2025年1月1日實施新的認證措施。詳情將於2024年11月公佈。謝謝。-->
<!--                      </p>-->
<!--                      <p>Press <a class="orange-text" v-on:click="tryMfa">here</a> to enable MFA <br>請<a class="orange-text" v-on:click="tryMfa">按此</a>啟動MFA</p>-->
<!--                      <p>Press <a class="orange-text" v-on:click="notTryMfa">here</a> to skip MFA <br>請<a class="orange-text" v-on:click="notTryMfa">按此</a>跳過MFA</p>-->
<!--                      <p>Press <a class="orange-text" v-on:click="downloadMfaInstruction">here</a> to download the MFA DEMO<br>請<a class="orange-text" v-on:click="downloadMfaInstruction">按此</a>下載MFA示範</p>-->
<!--                      <p><b-button class="orange-text" v-on:click="notTryMfa">已讀, 繼續登入程序<br>Continue Login Process</b-button></p>-->

<!--                    </b-card-text>-->
<!--                  </b-card-text>-->
<!--                </b-card>-->
<!--              </div>-->
<!--            </b-modal>-->
<!--          </b-tab>-->
<!--          <b-tab @click="tryMfa">-->
<!--            <template #title>-->
<!--              MFA Login<br>以MFA方式登入-->
<!--            </template>-->
<!--            <div id="my-captcha-container">-->
<!--                &lt;!&ndash; The contents of this container will be replaced by the captcha widget &ndash;&gt;-->
<!--            </div>-->
<!--            &lt;!&ndash; Login v1 &ndash;&gt;-->
            <b-card class="mb-0 ll-loginbox">
              <!-- <b-link class="kerry-logo" v-if="host > -1">
              </b-link> -->
              <b-img  v-if="host > -1"
                :src="require('@/assets/images/imgs/kerryexpress.svg')"
                fluid
                style="max-width: unset !important;
        width: 60%; !important;margin-left:20%"
                alt="logo"
              />
              <b-link class="brand-logo" v-else>
              </b-link>
              <!-- form -->
              <validation-observer
                ref="loginForm"
              >
                <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
                >
                  <!-- username -->
                  <b-form-group
                    label-for="username"
                    label="Username"
                  >
                    <b-form-input
                      id="username"
                      v-model="userInfoForm.username"
                      name="login-username"
                      placeholder="Username"
                      autofocus
                      @keyup.enter="focusNext('pwd')"
                    />
                  </b-form-group>

                  <!-- password -->
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="password">Password</label>
                      <!--
                      <b-link :to="{name:'auth-forgot-password-v1'}">
                        <small>Forgot Password?</small>
                      </b-link>
                      -->
                    </div>
                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="password"
                          v-model="userInfoForm.password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          name="login-password"
                          placeholder="Password"
                          ref="pwd"
                        />

                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                  </b-form-group>

                  <b-form-group v-if="showFirstMfa">
                    <div class="d-flex justify-content-between">
                      <label for="email">MFA Method</label>
                    </div>
                    <b-input-group
                    >
                      <b-form-radio-group>
                        <b-form-radio
                          v-model="userInfoForm.mfaMethod"
                          name="mfaMethod"
                          value="EMAIL"
                          id="email"
                        />
                        <label for="email" class="switch-label mr-2">Email</label>
                        <b-form-radio
                          v-model="userInfoForm.mfaMethod"
                          name="mfaMethod"
                          value="SMS"
                          id="phone"
                        />
                        <label for="phone" class="switch-label">Mobile Number</label>
                      </b-form-radio-group>
                      <div class="h2 mb-0 ml-2">
                        <b-icon-question-circle-fill id="mfa-tip" style="color:grey"></b-icon-question-circle-fill>
                        <b-tooltip class="custom-tooltip" target="mfa-tip" triggers="hover">
                          驗證碼發送方式會以客戶首次登記時之選擇送出, 如有更改或需要協助, 請與我們客戶服務部聯絡。<br>The MFA code sending method will be sent according to first customer MFA registration. Please contact our customer service department for any changes or assistance.
                        </b-tooltip>
                      </div>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group v-if="showEmail">
                    <div class="d-flex justify-content-between">
                      <label for="email">Email</label>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="email"
                        v-model="userInfoForm.email"
                        class="form-control-merge"
                        name="login-email"
                        placeholder="Email"
                        ref="email"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group v-if="showPhone">
                    <div class="d-flex justify-content-between">
                      <label for="phone">Mobile Number</label>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="phone"
                        v-model="userInfoForm.phone"
                        class="form-control-merge"
                        name="login-phone"
                        placeholder="Mobile Number"
                        ref="phone"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group v-if="showMfaCode">
                    <div class="d-flex justify-content-between">
                      <label for="mfaCode">MFA Code</label>
                    </div>
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="mfaCode"
                        v-model="userInfoForm.mfaCode"
                        class="form-control-merge"
                        name="login-mfaCode"
                        placeholder="MFA Code"
                        ref="mfaCode"
                      />
                    </b-input-group>
                  </b-form-group>

                  <!-- checkbox -->
                  <b-form-group>
                    <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                      v-if="false"
                    >
                      Remember Me
                    </b-form-checkbox>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button
                    variant="primary"
                    type="submit"
                    block
                    @click.prevent="recaptcha"
                    :disabled="disabled"
                    :style="{'cursor': disabled ? 'no-drop' : 'pointer'}"
                  >
                    Sign in
                  </b-button>
                  <b-button
                    variant="secondary"
                    type="submit"
                    block
                    @click.prevent="resendMfaCode"
                    :disabled="disableResend"
                    :style="{'cursor': disableResend ? 'no-drop' : 'pointer'}"
                    v-if="showResendMfaCode"
                  >
                    Resend MFA Code {{ disableCount > 0 ? '(' + disableCount + ')' : '' }}
                  </b-button>

                  <b-form-group class="pt-1 text-center">
                    <b-link :to="{name:'track'}">
                      <small>Public Track and Trace</small>
                    </b-link>
                  </b-form-group>
                </b-form>
              </validation-observer>
            </b-card>
            <!-- /Login v1 -->
            <!-- modal -->
            <b-modal
              id="modal-details"
              ref="modal-details"
              centered
              title=""
              size="l"
              hide-footer
            >
              <div class="flex-grow-1">
                <b-card class="card-browser-states ll-backg ll-sHei ll-cardHei1">
                  <b-card-text>
                    <b-card-title class="text-center">
                      <div class="kerry-mfa-logo"></div>

                    </b-card-title>
                    <b-card-text>
                      <p>
                        Notice: To enhance user experience and improve system security. New security measure will be implemented from 01-January-2025. Details will be announced in November 2024. Thanks.<br/><br/>注意:為了令用戶有方便的使用體驗，同時提昇系統的安全性。我們將會於2025年1月1日實施新的認證措施。詳情將於2024年11月公佈。謝謝。
                      </p>
                      <br>
<!--                      <p>Press <a class="orange-text" v-on:click="tryMfa">here</a> to enable MFA <br>請<a class="orange-text" v-on:click="tryMfa">按此</a>啟動MFA</p>-->
<!--                      <p>Press <a class="orange-text" v-on:click="notTryMfa">here</a> to skip MFA <br>請<a class="orange-text" v-on:click="notTryMfa">按此</a>跳過MFA</p>-->
<!--                      <p>Press <a class="orange-text" v-on:click="downloadMfaInstruction">here</a> to download the MFA DEMO<br>請<a class="orange-text" v-on:click="downloadMfaInstruction">按此</a>下載MFA示範</p>-->
                      <p class="text-center"><b-button class="orange-button" v-on:click="notTryMfa">繼續登入程序<br>Continue Login Process</b-button></p>

                    </b-card-text>
                  </b-card-text>
                </b-card>
              </div>
            </b-modal>
<!--          </b-tab>-->
<!--        </b-tabs>-->
<!--      </b-card>-->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BImg,
  BIconQuestionCircleFill,
  BTabs,
  BTab
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from "@/libs/fun.js"

export default {
  components: {
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BTabs,
    BTab,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BIconQuestionCircleFill,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userInfoForm: {
        username: null,
        password: null,
        deviceId: null,
        email: null,
        phone: null,
        mfaMethod: null,
        mfaContact: null,
        mfaCode: null,
        askedMfa: false,
        tryMfa: false,
      },
      status: '',
      // validation rules ordering.hk.kerryexpress.com
      required,
      email,
      disabled: null,
      disableResend: true,
      disableCount: 60,
      showMfaCode: false,
      showResendMfaCode: false,
      showFirstMfa: false,
      showEmail: false,
      showPhone: false,
      modalMessage: '',
      host:window.location.host.indexOf('ordering.hk.kerryexpress.com')
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    'userInfoForm.username'(val) {
      if (val !== '') {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    'userInfoForm.password'(val) {
      if (val !== '') {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },
    'userInfoForm.mfaMethod'(val) {
      if (val === 'EMAIL') {
        this.showEmail = true
        this.showPhone = false
        this.$nextTick(() => this.$refs.email.focus())
      } else {
        this.showEmail = false
        this.showPhone = true
        this.$nextTick(() => this.$refs.phone.focus())
      }
    },
  },
  created() {
    // if (this.userInfoForm.username === null || this.userInfoForm.password === null) {
    //   this.disabled = true
    // } else {
    //   this.disabled = false
    // }
    // console.info(this.host>-1,'0--')
  },
  mounted() {
    sessionStorage.clear()
    // localStorage.clear()
    this.getCookie()
  },
  destroyed() {},
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "doLogin".
      // const token = await this.$recaptcha('doLogin')
      // console.log(token)
      this.doLogin()
    },
    getCookie() {
      if (document.cookie.length>0) {
        const arr=document.cookie.split('; ')
        // console.log(arr)
        if (arr[1] !== undefined) {
          const ati = arr[1].split('=')
          this.userInfoForm._ati = ati[1]
          if (arr[2] !== undefined) {
            const token = arr[2].split('=')
            sessionStorage.setItem('token', token[1])
            axios.defaults.headers.Authorization = token[1]
          }
        } else {
          const c = document.cookie
          const a = c.lastIndexOf('\=')
          this.userInfoForm._ati = c.substring(a+1, c.length)
        }
      }
      if (localStorage.getItem('userInfo') !== null) {
        this.$router.push({ name: 'dashboard' })
      }
      const uuid = this.$uuid.v4()
      if (this.$cookies.get('uuid') !== undefined) {
        this.userInfoForm.deviceId = this.$cookies.get('uuid')
        if (this.userInfoForm.deviceId === null) {
          this.userInfoForm.deviceId = uuid
          this.$cookies.set('uuid', uuid, 60 * 60 * 24 * 30)
        }
      } else {
        // generate uuid
        this.$cookies.set('uuid', uuid, 60 * 60 * 24 * 30)
        this.userInfoForm.deviceId = uuid
      }
      // console.log(this.userInfoForm.deviceId)
    },
    focusNext() {
      this.$refs.pwd.focus()
    },
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          // this.doLogin()
          this.recaptcha()
        }
      })
    },
    tryMfa() {
      this.showFirstMfa = true
      this.userInfoForm.askedMfa = true
      this.userInfoForm.tryMfa = true
      this.$refs['modal-details'].hide()
    },
    notTryMfa() {
      this.showFirstMfa = false
      this.userInfoForm.askedMfa = true
      this.userInfoForm.tryMfa = false
      this.$refs['modal-details'].hide()
      // focus on username input
      // this.$nextTick(() => this.$refs.username.focus())
      // // this.doLogin()
      this.recaptcha()
    },
    resendMfaCode() {
      this.userInfoForm.mfaCode = null
      // this.doLogin()
      this.recaptcha()
    },
    downloadMfaInstruction: debounce(function() {
      let url = axios.defaults.baseURL+`/user/downloadMfaInstruction`
      window.open(url, "_self")
    }, 1000),
    doLogin() {
      this.disableCount = 60
      this.disableResend = true
      if (this.userInfoForm.mfaMethod == 'EMAIL') {
        this.userInfoForm.mfaContact = this.userInfoForm.email
      }
      if (this.userInfoForm.mfaMethod == 'SMS') {
        this.userInfoForm.mfaContact = this.userInfoForm.phone
      }
      this.$http.post('/user/login', this.userInfoForm).then(res => {
        const info = res.data.data
        if (res.data.message == "1003") {

        }
        else if (res.data.message == "1002") {
          this.$refs['modal-details'].show()
          return true;
        }
        // if require mfa code, show mfa code input
        else if (res.data.message == "1000" || res.data.message == "1001") {
          this.showMfaCode = true
          this.showResendMfaCode = true
          // count down 60s to make resend button enable
          this.disableCount = 60
          const timer = setInterval(() => {
            this.disableCount -= 1
            if (this.disableCount <= 0) {
              clearInterval(timer)
              this.disableResend = false
            }
          }, 1000)
          if (res.data.message == "1000") {
            if (this.userInfoForm.email == null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Please check your email and enter the MFA code",
                  icon: 'CrosshairIcon',
                  type: 'info',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Please check your email: " + this.userInfoForm.email + " and enter the MFA code",
                  icon: 'CrosshairIcon',
                  type: 'info',
                },
              })
            }
          } else if (res.data.message == "1001") {
            if (this.userInfoForm.phone == null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Please check your mobile number and enter the MFA code",
                  icon: 'CrosshairIcon',
                  type: 'info',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Please check your mobile number: " + this.userInfoForm.phone + " and enter the MFA code",
                  icon: 'CrosshairIcon',
                  type: 'info',
                },
              })
            }

          }


          this.$nextTick(() => this.$refs.mfaCode.focus())
          return true;
        } else if (res.data.code != 200) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: res.data.message,
          //     icon: 'XIcon',
          //     variant: 'danger',
          //   },
          // })
          return false;
        } else {
          localStorage.setItem('userInfo', JSON.stringify(info))
          sessionStorage.setItem('token', info.token)
          sessionStorage.setItem('userInfo', JSON.stringify(info))
          // window.document.cookie= JSON.stringify(info)
          // const token = sessionStorage.getItem('token')
          let token = JSON.parse(localStorage.getItem('userInfo')).token
          axios.defaults.headers.Authorization = token
          if (token !== null) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'dashboard' })
          }
        }
      }).catch(error => {
        // console.log(error)
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Server is bussy now, please try again later',
        //     icon: 'XIcon',
        //     variant: 'danger',
        //   },
        // })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.ll-loginbox > .card-body{
  padding: 5rem 1.5rem;
}
.brand-logo {
  width: 100%;
  height: 70px;
}
.kerry-logo{
  width: 100% !important;
  height: 70px !important;
}
.kerry-mfa-logo{
  text-align: center;
  width: 100% !important;
  height: 50px !important;
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 50% !important;

}
.ll-loginbox .kerry-logo{
  background: url('../../assets/images/imgs/kerryexpress.svg') no-repeat center !important;
  background-size: 80%;
}
.ll-loginbox .brand-logo{
  background: url('../../assets/images/imgs/loginLogo.svg') no-repeat center;
  background-size: 80%;
}
.orange-text {
  color: #FB7800 !important;
  background: transparent !important;
}
.orange-button {
  color: white !important;
  background: #FB7800 !important;
}


.custom-tooltip > .tooltip-inner {
  max-width: 1000px !important;
  width: 500px !important;
}
</style>
